// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta3/dist/css/bootstrap.min.css);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://unpkg.com/sweetalert2@9/dist/sweetalert2.min.css);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#app{font-family:Avenir,Helvetica,Arial,sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;color:#2c3e50}nav{width:100%;height:100px;background-image:linear-gradient(15deg,#13547a,#80d0c7);font-size:0;box-shadow:0 2px 3px 0 rgba(0,0,0,.1)}nav,nav a{position:relative}nav a{font-size:30px;text-transform:uppercase;text-decoration:none;line-height:100px;z-index:1;display:inline-block;text-align:center;font-weight:700}nav a,nav a:hover{color:#fff}nav .animation{position:absolute;height:100%;top:0;z-index:0;background:#64a8a0;border-radius:8px;transition:all .5s ease 0s}nav a:first-child{width:110px}nav a:first-child:hover~.animation{width:110px;left:0}nav a:nth-child(2){width:110px}nav a:nth-child(2):hover~.animation{width:110px;left:110px}nav a:nth-child(3){width:110px}nav a:nth-child(3):hover~.animation{width:110px;left:220px}nav a:nth-child(4){width:110px}nav a:nth-child(4):hover~.animation{width:110px;left:330px}nav a:nth-child(5){width:110px}nav a:nth-child(5):hover~.animation{width:110px;left:440px}nav a:nth-child(6){width:110px}nav a:nth-child(6):hover~.animation{width:110px;left:550px}.t1{right:15px}.t1,.user{font-size:45px;text-transform:none;position:absolute}.user{right:250px}nav a.router-link-exact-active{color:#42b983}@media screen and (max-width:800px){nav{display:none}}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
